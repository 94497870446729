import React from "react";

const TradeBanners = ({ banner }) => {
	return (
		<>
			<div className='mb-5 mb-md-3'>
				<div
					className='trade-info'
					style={{ backgroundImage: `url(${banner})` }}></div>
			</div>
		</>
	);
};

export default TradeBanners;
