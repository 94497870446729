import React, { useEffect, useState } from "react";
import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ToastContainer } from "react-toastify";

// Import Styles Files
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";

import {
	FooterOverlay,
	Header,
	LoadingPage,
	NotFoundPage,
	WhatWeOfferDetails,
} from "../components";
import { Home } from "./index";

// RTK Query
import { useGetHomePageDataQuery } from "../RTK/Api/homeApi";

// use spouse to handle lazy loading..
const Blog = React.lazy(() => import("./Blog"));
const Start = React.lazy(() => import("./Start"));
const BlogDetail = React.lazy(() => import("./BlogDetail"));
const WebsitePages = React.lazy(() => import("./WebsitePages"));
const Services = React.lazy(() => import("./Services/Services"));
const PackagesPage = React.lazy(() => import("./PackagesPage/PackagesPage"));
const CourseDetails = React.lazy(() => import("./PackagesPage/CourseDetails"));
const SouqAtlbhaProducts = React.lazy(() => import("./SouqAtlbhaProducts"));
const SuccessCheckOut = React.lazy(() =>
	import("../components/PaymentStatus/SuccessCheckout")
);
const FailedCheckout = React.lazy(() =>
	import("../components/PaymentStatus/FailedCheckout")
);

/* Business Trip Dubai content */
const BusinessStorePage = React.lazy(() =>
	import("./BusinessFlights/BusinessStorePage")
);
const BusinessTripDubai = React.lazy(() =>
	import("./BusinessFlights/BusinessTripDubai")
);
// const ContactUs = React.lazy(() => import("./ContactUs"));
const Faqs = React.lazy(() => import("./Faqs/Faqs"));
const OurWorks = React.lazy(() => import("./OurWorks/OurWorks"));

const AllPages = () => {
	// to handle pagination of stores
	const [pageTarget, setPageTarget] = useState(1);
	const [displayStores, setUseDisplayStores] = useState(12);

	const {
		data: homePageData,
		isLoading,
		isFetching,
	} = useGetHomePageDataQuery({
		page: pageTarget,
		number: displayStores,
	});

	localStorage.setItem("souqLogo", homePageData?.logo);

	const updateFavicons = (faviconUrl) => {
		const links = document.querySelectorAll('link[rel*="icon"]');
		links.forEach((link) => {
			link.href = faviconUrl;
		});
	};

	useEffect(() => {
		if (homePageData?.favicon) {
			updateFavicons(homePageData.favicon);
		}
	}, [homePageData]);

	return (
		<>
			<div className='w-100 overflow-hidden'>
				<BrowserRouter
					future={{
						v7_startTransition: true,
						v7_relativeSplatPath: true,
					}}>
					<Header homeFooter={homePageData?.footer} logo={homePageData?.logo} />

					<ToastContainer
						rtl
						draggable
						closeOnClick
						pauseOnHover
						autoClose={5000}
						pauseOnFocusLoss
						position='top-right'
						newestOnTop={false}
						hideProgressBar={false}
						theme='colored'
						style={{ fontSize: "14px", color: "#000", whiteSpace: "normal" }}
					/>

					<Routes>
						<Route
							path=''
							element={
								<Suspense fallback={<LoadingPage />}>
									<Home
										isFetching={isFetching}
										homePageData={homePageData}
										homeLoadingData={isLoading}
										pageTarget={pageTarget}
										setPageTarget={setPageTarget}
										displayStores={displayStores}
										setUseDisplayStores={setUseDisplayStores}
									/>
								</Suspense>
							}
						/>

						<Route
							path='/services'
							element={
								<Suspense fallback={<LoadingPage />}>
									<Services />
								</Suspense>
							}
						/>
						<Route
							path='/success'
							element={
								<Suspense>
									<SuccessCheckOut />
								</Suspense>
							}
						/>
						<Route
							path='/failed'
							element={
								<Suspense>
									<FailedCheckout />
								</Suspense>
							}
						/>
						<Route
							path='/business-store/:id'
							element={
								<Suspense>
									<BusinessStorePage />
								</Suspense>
							}
						/>

						<Route
							path='/business-trips/:id'
							element={
								<Suspense>
									<BusinessTripDubai />
								</Suspense>
							}
						/>

						{/* Package Info */}
						<Route
							path='/package-info/:id'
							element={
								<Suspense>
									<BusinessStorePage />
								</Suspense>
							}
						/>

						<Route
							path='/package-courses/:id'
							element={
								<Suspense>
									<BusinessTripDubai />
								</Suspense>
							}
						/>
						<Route
							path='/courses-details/:id/:id'
							element={
								<Suspense fallback={<LoadingPage />}>
									<CourseDetails />
								</Suspense>
							}
						/>

						<Route
							path='/packages'
							element={
								<Suspense fallback={<LoadingPage />}>
									<PackagesPage />
								</Suspense>
							}
						/>
						<Route
							path='/blog'
							element={
								<Suspense fallback={<LoadingPage />}>
									<Blog />
								</Suspense>
							}
						/>
						<Route
							path='/post/:id/:title'
							element={
								<Suspense fallback={<LoadingPage />}>
									<BlogDetail />
								</Suspense>
							}
						/>
						<Route
							path='/start'
							element={
								<Suspense>
									<Start />
								</Suspense>
							}
						/>

						<Route
							path='/souq_atlbha_products'
							element={
								<Suspense fallback={<LoadingPage />}>
									<SouqAtlbhaProducts pageTitle={homePageData?.section2} />
								</Suspense>
							}
						/>

						<Route
							path='/Page/:id/:title'
							element={
								<Suspense fallback={<LoadingPage />}>
									<WebsitePages />
								</Suspense>
							}
						/>
						<Route
							path='/whatWeOffer/:title'
							element={<WhatWeOfferDetails />}
						/>

						{/*	<Route
							path='/contact-us'
							element={
								<Suspense>
									<ContactUs />
								</Suspense>
							}
						/>*/}

						<Route
							path='/faqs'
							element={
								<Suspense fallback={<LoadingPage />}>
									<Faqs />
								</Suspense>
							}
						/>

						{/* our works pages */}

						<Route
							path='/our-works'
							element={
								<Suspense fallback={<LoadingPage />}>
									<OurWorks pagesCategory={homePageData?.ourwork_category} />
								</Suspense>
							}
						/>

						<Route path='*' element={<NotFoundPage />} />
					</Routes>
					<FooterOverlay
						ourwork_category={homePageData?.ourwork_category}
						registrationMarketer={homePageData?.registration_marketer}
						linkWebsite={homePageData?.website_socialmedia}
						logoFooter={homePageData?.logo_footer}
						homeFooter={homePageData?.footer}
					/>
				</BrowserRouter>
			</div>
		</>
	);
};

export default AllPages;
