import React, { useEffect, useState } from "react";

// third party
import { useScrollYPosition } from "react-use-scroll-position";

// components
import FooterLogo from "./FooterLogo";
import FooterInfo from "./FooterInfo";
import FooterAboutUs from "./FooterAboutUs";

// css styles
import "./FooterOverlay.css";
import FooterLinks from "./FooterLinks";
import FooterHelpLinks from "./FooterHelpLinks";
import BackToTop from "./BackToTop";
import AllRightsReserved from "./AllRightsReserved";

const FooterOverlay = ({
	ourwork_category,
	linkWebsite,
	homeFooter,
	registrationMarketer,
	logoFooter,
}) => {
	const scrolly = useScrollYPosition();
	const [showup, setShowup] = useState(false);

	useEffect(() => {
		if (scrolly >= 500) {
			setShowup(true);
		} else {
			setShowup(false);
		}
	}, [scrolly]);

	// handle open page in top position
	let goUpWindow = () => {
		window.scroll(0, 0);
	};

	// We use this filter to get all pages about category معلومات
	const information = homeFooter?.filter((page) =>
		page?.pageCategory?.filter((category) => category?.name === "معلومات")
	);

	// We use this filter to get all pages about category من نحن
	const aboutUsPage = homeFooter?.find(
		(page) =>
			page?.pageCategory?.some((category) => category?.name === "معلومات") &&
			page?.title === "من نحن"
	);

	return (
		<>
			<footer className='main-footer'>
				<div className='all'>
					<div className='container flex-column flex-lg-row  align-items-center align-items-lg-start '>
						{/* footer logo */}
						<FooterLogo logoFooter={logoFooter} linkWebsite={linkWebsite} />

						<nav className='box-right'>
							<ul
								className={`${
									ourwork_category?.length === 0
										? " justify-content-center"
										: ""
								}`}>
								{/* Footer about us */}
								{aboutUsPage ? (
									<FooterAboutUs
										aboutUsPage={aboutUsPage}
										goUpWindow={goUpWindow}
									/>
								) : null}

								{/* Footer Info */}
								{homeFooter?.length > 0 && information?.length > 0 ? (
									<FooterInfo
										title='معلومات'
										links={homeFooter}
										information={information}
										goUpWindow={goUpWindow}
									/>
								) : null}

								{ourwork_category?.length > 0 ? (
									<FooterInfo
										title='أعمالنا'
										links={ourwork_category}
										goUpWindow={goUpWindow}
									/>
								) : null}

								{/* Footer Links */}
								<FooterLinks registrationMarketer={registrationMarketer} />

								{/* Footer Help Links */}
								{/*<FooterHelpLinks goUpWindow={goUpWindow} />*/}
							</ul>
						</nav>
					</div>

					<AllRightsReserved />
				</div>
				<BackToTop showup={showup} />
			</footer>
		</>
	);
};

export default FooterOverlay;
