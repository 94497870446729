import React from "react";

const FooterLogo = ({ logoFooter, linkWebsite }) => {
	return (
		<div className='box-left'>
			<a className='main-footer__logo' href='#'>
				<img
					width='100%'
					height='100%'
					src={logoFooter}
					alt=''
					loading='lazy'
				/>
			</a>

			<ul>
				{linkWebsite?.length > 0
					? linkWebsite?.map((el, idx) => {
							return (
								<li key={el?.id}>
									<a href={el.link}>
										<img
											src={el.logo}
											alt='لوجو'
											width='100%'
											height='100%'
											loading='lazy'
										/>
									</a>
								</li>
							);
					  })
					: null}
			</ul>
		</div>
	);
};

export default FooterLogo;
