import React from "react";

const MiddleBanners = ({ right_banner, left_banner }) => {
	return (
		<div className='d-flex flex-column flex-md-row justify-content-center align-items-center middle-banners'>
			<div className='w-100'>
				<img className='w-100' src={right_banner} alt='banners' />
			</div>
			<div className='w-100'>
				<img className='w-100' src={left_banner} alt='banners' />
			</div>
		</div>
	);
};

export default MiddleBanners;
