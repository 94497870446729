import React from "react";
import MainTitle from "../MainTitle/MainTitle";
import NotFoundData from "../NotFoundData/NotFoundData";
import SouqAtlbhaSwiper from "../SwiperBox/SouqAtlbhaProductsSwiper/SouqAtlbhaSwiper";

const HomePageSection = ({ sectionTitle, sliderData }) => {
	return (
		<div className='stores-info p-main'>
			<div className='container'>
				<MainTitle text={sectionTitle} />
				<div style={{ marginTop: "40px" }}>
					{sliderData?.length > 0 ? (
						<SouqAtlbhaSwiper productSwiperData={sliderData} />
					) : (
						<NotFoundData />
					)}
				</div>
			</div>
		</div>
	);
};

export default HomePageSection;
